import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/reserve",
    name: "Reserve",
    component: () => import("../views/Reserve.vue"),
  },
  {
    path: "/gallery",
    name: "WrapperGallery",
    component: () => import("../views/Gallery.vue"),
  },
  {
    path: "/photos/:photo",
    name: "Photos",
    component: () => import("../views/Photos.vue"),
  },
  {
    path: "/faq",
    name: "Faq",
    component: () => import("../views/Faq.vue"),
  },
  {
    path: "/menu",
    name: "Menu",
    component: () => import("../views/Menu.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

export default router;
