export default {
  "app": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["home"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "photos": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pictures"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menu"])},
    "tags": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chinees Restaurant, Restaurant"])},
    "place": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontich, Belgium"])}
  },
  "home": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Experience the taste of a real family restaurant."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hong Fu is a family restaurant located in the heart of Kontich and has been around for more than 15 years.\n \n The Wang family who runs this business is from Wenzhou, China. In Wenzhou, 'Hong Fu, 鸿福, means 'everyone happy'.\n \n All dishes are always prepared with the freshest of ingredients and with a lot of love. \n Hope to see you soon!"])},
    "questions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Would you like to make a reservation, place an order to take away or do you have another question?"])},
    "c1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "c2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "c3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["us"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
    "menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu"])},
    "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We are on annual leave from July 18 to August 3. We will be open again on Friday, August 4 from 12 noon. \n Enjoy the beautiful weather and we will see you again soon. 🌴"])}
  },
  "contact": {
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You want to order, make a reservation or have a question?"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us and we are happy to help you"])},
    "facebook": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can also contact us via Facebook by pressing the chat icon at the bottom right."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a question? Be sure to check out our"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frequently Asked Questions"])},
    "days1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-Wed, Friday and Saturday"])},
    "days2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday: closed"])},
    "days3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sundays and holidays"])},
    "route": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Generate route"])},
    "reviews": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See reviews"])}
  },
  "faq": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Have a question?"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You may already find an answer here."])},
    "subtitle2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you have another question?"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact us:"])},
    "q1": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What payment options are there?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can pay with cash, bancontact or by QR code."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note: Visa, Mastercard, foreign cards and ApplePay do not work at our restaurant."])}
    },
    "q2": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you deliver at home?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No, unfortunately we do not do delivery. \n You can take out or come and eat on the spot."])}
    },
    "q3": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["What are your opening hours?"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-Wed, Friday and Saturday"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday: closed"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sundays and holidays"])}
    },
    "q4": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you open on public holidays?"])},
      "a1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes, we are open on"])},
      "a2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["all"])},
      "a3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holidays."])}
    },
    "q5": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Do you sell gift cards?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can buy a gift card at the counter."])}
    },
    "q6": {
      "q": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Can I place an order via email or Facebook?"])},
      "a": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["If you place your order at least 24 hours in advance, you can place it via email or Facebook. Would you like to order for the same day? \n Give us a call. That way, we can guarantee that your order will reach the kitchen on time. \n\n It can get very busy, which can lead to waiting times which also means we cannot answer your message nor guarantee the slot of time of your wish."])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please note: if we do not confirm an order within 25 minutes, it is not guaranteed. In that case, be sure to call."])}
    }
  },
  "menu": {
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The prices shown are for takeaway only."])},
    "order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Want to order?"])},
    "days1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mon-Wed, Friday and Saturday"])},
    "days2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Thursday: closed"])},
    "days3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sundays and holidays"])},
    "menus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["menus"])}
  }
}