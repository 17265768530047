<template>
  <div class="body">
    <div id="header">
      <div id="image-wrapper">
        <div id="header-info">
          Molenstraat 7, 2550 Kontich |
          <a id="call" class="text-muted" href="tel:003234581431"
            >+32 3 458 14 31</a
          >
        </div>
        <router-link to="/"
          ><img src="./assets/hongfu-red-light-crop.png"
        /></router-link>
      </div>
      <div id="nav">
        <router-link to="/">{{ $t("app.home") }}</router-link>
        <router-link to="/reserve">{{ $t("app.contact") }}</router-link>
        <router-link to="/gallery">{{ $t("app.photos") }}</router-link>
        <router-link to="/faq">{{ $t("app.faq") }}</router-link>
        <router-link
          id="menu-nav"
          v-if="currentRouteName != 'Home'"
          to="/menu"
          >{{ $t("app.menu") }}</router-link
        >
      </div>
      <div id="menu-btn-wrapper">
        <router-link to="/menu">{{ $t("app.menu") }}</router-link>
      </div>
      <div class="clearfix"></div>
      <div class="language">
        <button
          :class="{
            selected: $i18n.locale == 'nl',
          }"
          @click="changeLocale('nl')"
        >
          NL
        </button>
        <button
          :class="{
            selected: $i18n.locale == 'en',
          }"
          @click="changeLocale('en')"
        >
          EN
        </button>
      </div>
    </div>
    <router-view />
    <div id="footer">
      © 2023 Hong Fu Wang BV | BE 0756.475.086
      <span class="hideOnMobile"
        >| {{ $t("app.tags") }} | {{ $t("app.place") }} |
        <router-link to="/reserve"
          >{{ $t("app.contact") }}&nbsp;</router-link
        ></span
      >| Made by
      <a href="https://axellejamous.github.io/website/" target="_blank"
        ><u
          ><span class="hideOnMobile">Axelle Jamous</span
          ><span class="showInlineOnMobile">Axelle</span></u
        ></a
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "App",
  methods: {
    changeLocale(lang) {
      this.$i18n.locale = lang;
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
  mounted() {
    this.changeLocale("nl");
  },
};
</script>

<style>
@font-face {
  font-family: "SourceSansPro Extra Light";
  src: local("SourceSansPro Extra Light"),
    url(../public/fonts/SourceSansPro/SourceSansPro-ExtraLight.ttf);
}
@font-face {
  font-family: "SourceSansPro Light";
  src: local("SourceSansPro Light"),
    url(../public/fonts/SourceSansPro/SourceSansPro-Light.ttf);
}
@font-face {
  font-family: "SourceSansPro";
  src: local("SourceSansPro"),
    url(../public/fonts/SourceSansPro/SourceSansPro-Regular.ttf);
}
@font-face {
  font-family: "SourceSansPro Bold";
  src: local("SourceSansPro Bold"),
    url(../public/fonts/SourceSansPro/SourceSansPro-Bold.ttf);
}

@font-face {
  font-family: "Fredoka";
  src: local("Fredoka"), url(../public/fonts/Fredoka/FredokaOne-Regular.ttf);
}
@font-face {
  font-family: "Nature";
  src: local("Nature"), url(../public/fonts/Nature.ttf);
}
@font-face {
  font-family: "September";
  src: local("September"), url(../public/fonts/September.otf);
}
@font-face {
  font-family: "Shelby";
  src: local("Shelby"), url(../public/fonts/Shelby.ttf);
}

html,
body,
#app,
.body {
  min-height: 100vh;
}
* {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
}
button {
  background: unset;
  border: unset;
}
button:hover {
  cursor: pointer;
}
a {
  text-decoration: none;
  color: unset;
}
div {
  white-space: pre-line;
}

.body {
  position: relative;
}
.template {
  height: calc(100vh - 165px);
}
.clearfix {
  clear: both;
}
.float-right {
  float: right;
}
.margin-bottom-25 {
  margin-bottom: 25px;
}
.title {
  font-size: 45px;
  text-transform: uppercase;
}
.subtitle {
  font-family: "SourceSansPro Light";
  font-size: 25px;
}
.showInlineOnMobile {
  display: none;
}
.language {
  display: flex;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.language .selected {
  font-weight: bold;
  color: #9e444a;
}
.language button:first-child {
  padding-right: 5px;
  margin-right: 5px;
  border-right: 1px solid #2c3e50;
}
.language button {
  color: #2c3e50;
}

#app {
  font-family: SourceSansPro;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#header-info {
  font-family: SourceSansPro Bold;
}
#header {
  display: flex;
  background-color: #f3efe5;
}

#footer {
  font-family: SourceSansPro Extra Light;
  font-size: 12px;
  letter-spacing: 1px;
  color: #f3efe5;
  position: absolute;
  bottom: 0;
  width: 100%;
  background: black;
  z-index: 1111;
  padding: 15px 25px 15px 25px;
  text-align: center;
}
#footer a:hover {
  text-decoration: underline;
}

#image-wrapper,
#nav,
#nav a {
  display: flex;
  align-items: center;
}
#image-wrapper {
  height: 165px;
  padding-top: 15px;
  flex-direction: column;
}
#image-wrapper img {
  height: 100px;
  margin-left: 25px;
  margin-right: 25px;
}

#call {
  text-decoration: none;
  color: rgb(44, 62, 80);
}
#call:hover {
  text-decoration: underline;
}

#nav,
#menu-btn-wrapper {
  text-transform: uppercase;
}
#nav a,
#menu-btn-wrapper a {
  color: #2c3e50;
}
#nav a {
  font-family: SourceSansPro;
  padding-right: 15px;
  padding-left: 15px;
  height: 100%;
}
#nav a:hover {
  background-color: #e9e9e9;
}
#nav a.router-link-exact-active {
  color: #9e444a;
}
#nav a#menu-nav {
  display: none;
}

#menu-btn-wrapper {
  font-family: SourceSansPro Bold;
  font-size: 18px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  background-image: url("./assets/tada.gif");
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center;
}
#menu-btn-wrapper:hover {
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  #home {
    background: linear-gradient(rgba(41, 31, 37, 0.85), rgba(41, 31, 37, 0.85)),
      url("./assets/pictures/six.jpg");
  }
  .template {
    height: unset;
    flex-grow: 1;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: center;
  }
  .body {
    display: flex;
    flex-direction: column;
  }
  .hideOnMobile {
    display: none;
  }
  .showInlineOnMobile {
    display: inline;
  }
  .language {
    padding-bottom: 15px;
    justify-content: center;
  }
  #footer {
    position: unset;
    bottom: unset;
    margin-top: auto;
    padding: 15px 20px 15px 20px;
  }

  #home {
    position: static;
  }
  #header {
    flex-direction: column;
  }
  #image-wrapper {
    padding-top: unset;
  }
  #image-wrapper,
  #nav,
  #nav a {
    justify-content: center;
  }
  #nav {
    font-size: 15px;
    padding-bottom: 15px;
  }
  #nav a:hover {
    background-color: unset;
  }
  #nav a#menu-nav {
    display: flex;
  }
  #menu-btn-wrapper {
    display: none;
    width: 100%;
    margin-left: unset;
    height: 70px;
    background-size: 70px;
    padding: 15px 0px;
  }
}
</style>
